import type { NextPage } from "next";

const CardocWebview: NextPage = () => {
  return (
    <div css={{ padding: "20px", fontSize: "18px" }}>
      <li>🚀</li>
      <li>{process.env.NEXT_PUBLIC_MODE}</li>
    </div>
  );
};

export default CardocWebview;
